import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import collapse from '@alpinejs/collapse'
import ToastComponent from '../../vendor/usernotnull/tall-toasts/dist/js/tall-toasts'

Alpine.data('ToastComponent', ToastComponent)

window.Alpine = Alpine

Alpine.plugin(focus)
Alpine.plugin(collapse)
Alpine.start()

export default Alpine
